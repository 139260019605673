/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Damla Yagli (https://sketchfab.com/ddamlayagli)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/retro-computer-8f2fb159144544d694a475a674351650
title: Retro computer
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/scene.gltf");
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group
            position={[-3.6, 9.39, 41.01]}
            rotation={[-1.51, 0, 0]}
            scale={74.53}
          >
            <mesh
              geometry={nodes.Cube036_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[4.87, -0.96, 39.93]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={74.53}
          >
            <mesh
              geometry={nodes.Plane_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[-1.79, 16.6, -180.82]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={100}
          >
            <mesh
              geometry={nodes.Cube_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[-20.48, 121.27, -181.68]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={100}
          >
            <mesh
              geometry={nodes.Cube001_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[69.1, 56, -83.08]} scale={100}>
            <mesh
              geometry={nodes.Plane001_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[41.48, 56, -83.08]} scale={100}>
            <mesh
              geometry={nodes.Plane002_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[108, 35.28, -63.77]} scale={100}>
            <mesh
              geometry={nodes.Plane003_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[80.38, 35.28, -63.77]} scale={100}>
            <mesh
              geometry={nodes.Plane004_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[41.05, 7.01, -307.88]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={100}
          >
            <mesh
              geometry={nodes.Plane005_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[240.18, -6.9, 20.73]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={154.56}
          >
            <mesh
              geometry={nodes.Cube002_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[-4.6, 51.66, -273.29]} scale={100}>
            <mesh
              geometry={nodes.Circle_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[-4.6, 50.9, -273.29]} scale={100}>
            <mesh
              geometry={nodes.Circle001_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[-4.6, 50.15, -273.29]} scale={100}>
            <mesh
              geometry={nodes.Circle002_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[-13.18, 50.73, -272.38]}
            rotation={[0, 0, Math.PI / 2]}
            scale={100}
          >
            <mesh
              geometry={nodes.Circle006_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[-31.95, 51.66, -273.29]} scale={100}>
            <mesh
              geometry={nodes.Circle003_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[-31.95, 50.9, -273.29]} scale={100}>
            <mesh
              geometry={nodes.Circle004_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group position={[-31.95, 50.15, -273.29]} scale={100}>
            <mesh
              geometry={nodes.Circle005_Material001_0.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            position={[436.89, 881.61, 472.97]}
            rotation={[-Math.PI, 0.96, 2.32]}
            scale={100}
          />
          <group
            position={[-221.79, 1373.71, -272.09]}
            rotation={[0.07, 0.03, 0.16]}
            scale={100}
          >
            <group rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group
            position={[-705.78, 331.53, -272.09]}
            rotation={[0, 0, Math.PI / 2]}
            scale={100}
          >
            <group rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group
            position={[568.04, 0.41, -272.09]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            scale={100}
          >
            <group rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group
            position={[-18, 115.38, -13.75]}
            rotation={[1.49, -Math.PI / 2, 0]}
            scale={100}
          >
            <group rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group position={[-15.99, 101.28, 93.47]} scale={100}>
            <group rotation={[Math.PI / 2, 0, 0]} />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/scene.gltf");
